import { motion } from 'framer-motion'
import { Booking, Order } from '@/types/booking'
import { useEffect, useRef, useState } from 'react'

import { ContentChannelButton } from './ContentChannelButton'
import { TicketButton } from './TicketButton'
import TicketImage from './TicketImage'
import { TicketInfo } from './TicketInfo'
import useExistingBookingStore from '@/context/useExistingBookingStore'
import { useNavigate } from 'react-router-dom'
import { useTicketsStore } from '@/context/useTicketsStore'
import { useSeatMap } from '@/hooks/useSeatMap'
import useEventStore from '@/context/useEventStore'
import useCallRouteWithDirection from '@/hooks/useCallRouteWithDirection'
import { formattedDate } from '@/utils'

type TicketCardProps = {
  booking: Booking
  order: Order
  isFlipped: boolean
  isActiveTicket: boolean
  handleFlip?: () => void
}

export function TicketCard({
  booking,
  order,
  isFlipped,
  isActiveTicket,
  handleFlip,
}: TicketCardProps) {
  const navigate = useNavigate()
  const { setBooking } = useExistingBookingStore()
  const { mapSeats, setMapSeats } = useEventStore()
  const [loaded, setLoaded] = useState(false)
  const videoRef = useRef<HTMLVideoElement>(null)
  const { ticketVideoOnMap, setTicketVideoOn } = useTicketsStore()
  const isVideoPlaying = ticketVideoOnMap[order.uid]
  const { getSeatMap, setSearchingSeats } = useSeatMap()
  const callRouteWithDirection = useCallRouteWithDirection()

  // Split the formatted date into date and time parts
  const [date, time] = formattedDate(booking.start_time as Date)
    .replace(/,([^,]*, [^,]*),([^,]*)$/, '$1,$2')
    .split(',')

  useEffect(() => {
    setMapSeats(undefined)
    if (isActiveTicket) {
      getSeatMap(order)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isActiveTicket])

  useEffect(() => {
    if (isFlipped) {
      videoRef.current?.pause()
    }

    if (loaded) {
      const videoTimeout = setTimeout(async () => {
        if (!isFlipped && isActiveTicket) {
          await setTicketVideoOn(order.uid, true)
          await videoRef.current?.play()
        }
      }, 1500)

      return () => {
        clearTimeout(videoTimeout)
        setTicketVideoOn(order.uid, false)
        if (videoRef.current) {
          videoRef.current.currentTime = 0
          // eslint-disable-next-line react-hooks/exhaustive-deps
          videoRef.current.pause()
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [videoRef, isFlipped, isActiveTicket, loaded])

  const handleError = () => {
    navigate('/tickets')
  }

  return (
    <div
      id="ticket-card"
      className={`${
        order.state.upgrade.code === 3
          ? 'shadow-[0_0_10px_0px_yellow]'
          : 'shadow-[0_0_8px_0px_#CDC3FB]'
      } rounded-[40px] bg-white flex flex-col items-center w-[80vw] h-full max-h-[700px] max-w-[85vw]`}
    >
      <div
        className={`overflow-hidden relative flex items-end justify-center w-full h-full rounded-[40px]`}
      >
        {booking.preshow.event_uid && isActiveTicket && (
          <motion.div
            className="absolute h-[inherit] w-full flex justify-center bg-[black]"
            initial={{ opacity: 0 }}
            animate={{
              opacity: isVideoPlaying ? 1 : 0,
              zIndex: isVideoPlaying ? 1 : -1,
            }}
            transition={{ duration: 0.5 }}
            exit={{ opacity: 0, zIndex: -1 }}
            onClick={handleFlip}
          >
            <video
              key={booking.preshow.event_uid}
              ref={videoRef}
              data-testid="ticket-video"
              className="shadow-md w-full h-full object-cover"
              controls={false}
              muted
              playsInline
              onPlay={() => setTicketVideoOn(order.uid, true)}
              onEnded={() => setTicketVideoOn(order.uid, false)}
              src={booking.preshow.first_content.content}
              onError={handleError}
            />
          </motion.div>
        )}

        <motion.div
          className={`absolute w-full h-[inherit]`}
          initial={{ opacity: 1 }}
          animate={{
            opacity: isVideoPlaying ? 0 : 1,
          }}
          transition={{ duration: 0.5 }}
        >
          <TicketImage
            src={booking.thumbnail_url}
            loaded={loaded}
            setLoaded={setLoaded}
            handleFlip={handleFlip}
          />
        </motion.div>
        <div className="absolute top-4 px-2 py-[1px] text-[12px] max-w-[80%] flex gap-1 justify-center items-center no-wrap bg-white text-purple z-[500] rounded-full">
          <div className="flex flex-col no-wrap">
            <p className="date font-normal no-wrap">
              {date}
              {`,`}
              {time}
            </p>
          </div>
          {`|`}
          <p>{booking.theatre_name}</p>
        </div>
        <ContentChannelButton
          id="ticket-to-content-btn"
          data-testid="ticket-to-content-btn"
          onClick={async () => {
            await setBooking(booking)
            navigate(`/channel/${booking.preshow.event_uid}`)
          }}
        />
      </div>
      <div
        className={`w-full flex flex-col items-center gap-5 my-5 ${!isActiveTicket && 'pt-4 pb-6'}`}
        onClick={handleFlip && handleFlip}
      >
        <TicketInfo ticketInfo={booking} upgraded embeddedInTicket />

        {isActiveTicket && (
          <TicketButton
            isReady={
              navigator.onLine && (!!mapSeats || order.state.upgrade.code !== 1)
            }
            ticketStateCode={order.state.upgrade.code as number}
            ticketStateMsg={order.state.upgrade.msg}
            onClick={(e) => {
              if (
                navigator.onLine &&
                mapSeats &&
                order.state.upgrade.code === 1
              ) {
                e.stopPropagation()
                setSearchingSeats(true)
                callRouteWithDirection('/seat-map', false, 1)
              }
            }}
          />
        )}
      </div>
    </div>
  )
}
