import { CastChannelAction, ContentType } from '@/types/preshow'

import { create } from 'zustand'

// Define the store's state
type MeetTheCastState = {
  badge: string
  duration: number
  casting?: CastChannelAction
  setBadgeUrl: (value: string) => void
  setCasting: (value: CastChannelAction) => void
}

// Create the store
export const useMeetTheCastStore = create<MeetTheCastState>((set) => ({
  badge: '',
  duration: 3500,
  casting: undefined,
  setBadgeUrl: (value) => set({ badge: value }),
  setCasting: (value) => set({ casting: value }),
}))
