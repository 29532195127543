import { useTicketsStore } from '@/context/useTicketsStore'
import { syncSilent } from '@/service/Booking/syncSilent'
import { getWallet } from '@/service/Wallet/getWallet'
import { Booking, Order } from '@/types/booking'
import { useState, useRef, useCallback } from 'react'

export const useUserTickets = () => {
  const { setWallet, setWalletOrders } = useTicketsStore()
  const [isLoading, setLoadingTickets] = useState<boolean>(true)
  const [syncLoading, setSyncLoading] = useState<boolean>(false)
  const getWalletCallCounter = useRef<number>(0)

  const loadUserTickets = useCallback(async () => {
    try {
      const res = await getWallet()

      if (!res.length) {
        setLoadingTickets(false)
        return
      }

      setWallet(res)
      setWalletOrders(
        res.flatMap((booking: Booking) => booking.orders) as Order[],
      )
    } catch (error) {
      console.error('Error fetching wallet:', error)
    } finally {
      setSyncLoading(false)
      setLoadingTickets(false) // Ensure loading state is updated on error
    }

    // Increment wallet call counter
    getWalletCallCounter.current += 1

    // If called 3 times, trigger syncSilent
    if (getWalletCallCounter.current === 3) {
      syncSilent()
      getWalletCallCounter.current = 0 // Reset the counter
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setWallet])

  return {
    loadUserTickets,
    isLoading,
    syncLoading,
    setSyncLoading,
  }
}
