import { requestWithRetry } from '../axios'

export const swapSeats = async (
  order_uid: string,
  start_date: string,
  end_date: string,
) => {
  try {
    return await requestWithRetry(
      `swap/seats?order_uid=${order_uid}&start_date=${start_date}&end_date=${end_date}`,
      `get`,
    ).catch((err) => {
      console.error('Error swaping dates', err)
      return err
    })
  } catch (error: any) {
    console.log(error, 'error swaping dates')
    return error.response?.data
  }
}
