import * as React from 'react'
import { Slot } from '@radix-ui/react-slot'
import { cva, type VariantProps } from 'class-variance-authority'

import { cn } from '@/utils'
import { TicketStates } from '@/types'
import { useEffect, useState } from 'react'
import { AnimatePresence, motion } from 'framer-motion'
import { QrCodeIcon } from 'lucide-react'

const buttonVariants = cva(
  'relative p-[3px] overflow-hidden inline-flex items-center justify-center bg-purple font-bold capitalize w-[50%] min-w-[10vw] text-[yellow] whitespace-nowrap text-base font-body font-semibold ring-offset-white transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-slate-950 focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 dark:ring-offset-slate-950 dark:focus-visible:ring-slate-300',
  {
    variants: {
      upgradeDisabledDueTime: {
        true: 'pointer-events-none bg-purple5 text-purple3',
      },
    },
  },
)

export interface ButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement>,
    VariantProps<typeof buttonVariants> {
  asChild?: boolean
  upgradeDisabledDueTime?: boolean
  ticketStateCode: number
  ticketStateMsg: string
  isReady?: boolean
}

const TicketButton = React.forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      className,
      upgradeDisabledDueTime,
      asChild = false,
      ticketStateCode,
      ticketStateMsg,
      isReady,
      ...props
    },
    ref,
  ) => {
    const Comp = asChild ? Slot : 'button'
    upgradeDisabledDueTime = ticketStateCode === 2

    const [active, setActive] = useState<number>(0)
    const items: number[] = [0, 1, 2]

    useEffect(() => {
      const interval = setInterval(() => {
        setActive((prevActive) =>
          prevActive < items.length - 1 ? prevActive + 1 : 0,
        )
      }, 300)

      return () => clearInterval(interval)
    }, [items.length])

    return (
      <div className="flex flex-col w-full justify-start items-center space-y-6 ">
        <Comp
          className={cn(buttonVariants({ upgradeDisabledDueTime, className }))}
          ref={ref}
          id="ticket-btn"
          data-testid="ticket-btn"
          {...props}
        >
          <motion.div
            className={`absolute top-1/2 left-1/2 w-[300%] h-[500%] ${ticketStateCode === 1 && 'animate-spin-slow'}`}
            initial={{ opacity: 0 }}
            animate={{
              opacity: isReady ? 1 : 0,
            }}
            transition={{ duration: 0.3, delay: 0.6 }}
            style={{
              background:
                'conic-gradient(rgba(244, 255, 104, 0.3) 0deg, rgba(244, 255, 104, 1) 60deg, transparent 150deg)',
            }}
          ></motion.div>
          {isReady ? (
            <AnimatePresence>
              <motion.div
                className={`w-full h-full rounded-full bg-purple justify-center relative flex items-center justify-center gap-2 my-2`}
                initial={{ opacity: 0 }}
                animate={{
                  opacity: isReady ? 1 : 0,
                }}
                transition={{ duration: 0.3, delay: 0.2 }}
              >
                {ticketStateCode === 3
                  ? TicketStates.UPGRADED
                  : ticketStateCode === 0
                    ? TicketStates.VIEW_TICKET
                    : ticketStateMsg}
              </motion.div>
              {/* {ticketStateCode === 0 && (
                <span className="bg-white/90 rounded-full p-1 mr-1">
                  <QrCodeIcon color={'black'} />
                </span>
              )} */}
            </AnimatePresence>
          ) : (
            <div className="loading-indicator gap-1 w-full h-10 rounded-full bg-purple justify-center relative flex items-center justify-center">
              {items.map((number, index) => (
                <div
                  key={number}
                  className={`h-2 w-2 border transition rounded-sm border-yellow ${
                    active === index ? 'bg-yellow' : 'bg-transparent'
                  }`}
                ></div>
              ))}
            </div>
          )}
        </Comp>
        {/* Inline Animation Styles */}
        <style>
          {`
              @keyframes spinSlow {
                0% { transform: translate(-50%, -50%) rotate(0deg); }
                100% { transform: translate(-50%, -50%) rotate(360deg); }
              }
              .animate-spin-slow {
                animation: spinSlow 3s linear infinite;
              }
            `}
        </style>
      </div>
    )
  },
)
TicketButton.displayName = 'Button'

export { TicketButton, buttonVariants }
