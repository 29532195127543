import {
  Drawer,
  DrawerClose,
  DrawerContent,
  DrawerDescription,
  DrawerFooter,
  DrawerHeader,
  DrawerTitle,
} from '@/components/ui/drawer'
import { Button } from '@/components/ui/button'
import { Plus } from 'lucide-react'
import useCallRouteWithDirection from '@/hooks/useCallRouteWithDirection'

interface SwapTicketProps {
  swapOpen: boolean
  setSwapOpen: (value: boolean) => void
}

export const SwapTicket = ({ swapOpen, setSwapOpen }: SwapTicketProps) => {
  const callRouteWithDirection = useCallRouteWithDirection()

  return (
    <Drawer open={swapOpen} onClose={() => setSwapOpen(!swapOpen)}>
      <DrawerContent>
        <div className="mx-auto w-full max-w-sm">
          <DrawerHeader className="relative flex justify-between">
            <div className="flex flex-col gap-3">
              <DrawerTitle>{`Can’t make the show? `}</DrawerTitle>
              <DrawerDescription>{`Swap your dates here.`}</DrawerDescription>
            </div>
            <DrawerClose asChild>
              <Button
                variant="primaryDark"
                className="absolute top-0 right-0 py-0 px-4"
              >
                <Plus
                  width={32}
                  height={32}
                  className="z-10 rotate-45 font-light"
                />
              </Button>
            </DrawerClose>
          </DrawerHeader>
          <DrawerFooter>
            <DrawerClose
              asChild
              onClick={() => callRouteWithDirection('/swap-dates', false, 1)}
            >
              <Button variant="primaryYellow">{`I want to swap dates`}</Button>
            </DrawerClose>
          </DrawerFooter>
        </div>
      </DrawerContent>
    </Drawer>
  )
}
