import { MoveUp } from 'lucide-react'
import { Arrow } from '@/assets/Arrow'
import NavLayout from '@/layouts/NavLayout'
import { isVideo } from '@/utils'
import { useEffect, useRef, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { ChannelItem, ContentType } from '@/types/preshow'
import { ActionBar } from '@/components/upseat-ui/contentChannel/ActionBar'
import { GridTile } from '@/components/upseat-ui/contentChannel/GridTile'
import { MainVideo } from '@/components/upseat-ui/contentChannel/MainVideo'
import { getPreShow } from '@/service/PreShowEngagement/getPreShow'
import { AnimatePresence, motion } from 'framer-motion'
import useConditionalRender from '@/hooks/useConditionalRender'
import { useContentChannelStore } from '@/context/useContentChannelStore'
import useExistingBookingStore from '@/context/useExistingBookingStore'
import { useFullScreenContentStore } from '@/context/useFullScreenContentStore'
import { useMeetTheCastStore } from '@/context/useMeetTheCastStore'
import useScreenSize from '@/hooks/useScreenSize'
import { useUserStore } from '@/context/useUserStore'
import { UpSeatLogo } from '@/assets/logos/upseat_logo'
import { FullScreen } from './FullScreen'
import { MeetTheCast } from './MeetTheCast'

export const ContentChannel = () => {
  const { id } = useParams()
  const scrollContainerRef = useRef<HTMLDivElement>(null)
  const { user } = useUserStore()
  const { booking } = useExistingBookingStore()
  const navigate = useNavigate()
  const screen = useScreenSize()
  const { setCasting, setBadgeUrl } = useMeetTheCastStore()
  const { setFullScreenChannel } = useFullScreenContentStore()
  const { channelContent, setChannelContent, fullScreen, meetTheCast } =
    useContentChannelStore()
  const [isExclusive, setIsExclusive] = useState(true)
  const [mainContentLoaded, setMainContentLoaded] = useState(false)
  const [gridContent, setGridContent] = useState<ChannelItem[]>([])
  const [gridContentLoaded, setGridContentLoaded] = useState(false)

  useEffect(() => {
    if (!id && !booking) {
      navigate('/tickets')
    } else {
      const eventIUD = id || booking?.preshow.event_uid || ''
      getPreShow(eventIUD)
        .then((res) => {
          setChannelContent(res.data)
          setCasting(
            res.data.actions.find(
              (action: { action_type: ContentType }) =>
                action.action_type === ContentType.ACTOR,
            ),
          )
          setBadgeUrl(res.data.badge)
          setFullScreenChannel(
            res.data.channel
              .filter((cont: { content: string }) => isVideo(cont.content))
              .sort(
                (a: { y_axis: number }, b: { y_axis: number }) =>
                  a.y_axis - b.y_axis,
              ),
          )
        })
        .catch(console.error)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user])

  useEffect(() => {
    if (!gridContentLoaded) {
      setGridContent(
        (channelContent.channel as ChannelItem[])
          .filter((cont: ChannelItem) => cont.y_axis > 0)
          .sort((a: ChannelItem, b: ChannelItem) => a.y_axis - b.y_axis),
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [channelContent])

  useEffect(() => {
    if (gridContent.length) {
      setGridContentLoaded(true)
    }
  }, [gridContent])

  const scrollToTop = () => {
    if (scrollContainerRef.current && screen.width > 1024) {
      setTimeout(() => {
        if (scrollContainerRef.current) {
          scrollContainerRef.current.scrollTop = 0
        }
      }, 500)
      setTimeout(() => {
        if (scrollContainerRef.current) {
          scrollContainerRef.current.scrollTop = 0
        }
      }, 500)
    } else {
      window.scrollTo({ top: 0, behavior: 'smooth' })
      setTimeout(() => window.scrollTo(0, 0), 500)
    }
    setTimeout(() => window.dispatchEvent(new Event('resize')), 100)
  }

  return (
    <NavLayout ref={scrollContainerRef}>
      {fullScreen && <FullScreen />}
      {meetTheCast && <MeetTheCast />}
      <AnimatePresence>
        <motion.section
          id="pre-show-content-container"
          className="flex flex-col items-center h-full w-full min-h-[100svh] bg-purple"
          initial={{ visibility: 'visible' }}
          animate={{
            visibility: !fullScreen && !meetTheCast ? 'visible' : 'hidden',
            opacity: !fullScreen && !meetTheCast ? '100%' : '0',
            height: !fullScreen && !meetTheCast ? '100%' : '-100%',

            display: !fullScreen && !meetTheCast ? 'flex' : 'none',
          }}
          exit={{ opacity: 0, height: 0, transition: { duration: 0.1 } }}
          transition={{ duration: 0.5 }}
        >
          {useConditionalRender(
            !!channelContent && !!user,
            <motion.span
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ delay: 0.5, duration: 0.3 }}
              onClick={() => navigate('/tickets', { replace: true })}
              className="absolute cursor-pointer top-0 left-0 m-4 z-50 bg-purple1 text-white rounded-full w-8 h-8 flex items-center justify-center"
            >
              <Arrow />
            </motion.span>,
          )}
          <main className="w-full flex flex-col items-center overflow-y-auto">
            {!!channelContent.channel?.length && (
              <>
                <MainVideo
                  setMainContentLoaded={setMainContentLoaded}
                  badge={channelContent.badge}
                  video_url={
                    (channelContent.channel as ChannelItem[]).find(
                      (cont: ChannelItem) => cont.y_axis === 0,
                    )?.content as string
                  }
                  video_description={
                    (channelContent.channel as ChannelItem[]).find(
                      (cont: ChannelItem) => cont.y_axis === 0,
                    )?.description as string
                  }
                  name={channelContent.name}
                  isExclusive={isExclusive}
                  childVariants={undefined}
                />
                <motion.div
                  animate={{ opacity: mainContentLoaded ? 1 : 0 }}
                  transition={{ duration: 0.3 }}
                >
                  <GridTile
                    channelContent={gridContent}
                    badge={channelContent.badge}
                    name={channelContent.name}
                    childVariants={undefined}
                    video_url={''}
                    video_description={''}
                  />
                </motion.div>
              </>
            )}
            {mainContentLoaded && (
              <>
                <ActionBar />
                <footer className="w-full bg-purple flex flex-col pt-12 pb-32 gap-1">
                  {channelContent.channel.length > 2 ? (
                    <p
                      className="text-yellow text-sm cursor-pointer flex flex-col items-center"
                      onClick={scrollToTop}
                    >
                      <MoveUp color="rgba(244, 255, 104, 1)" />
                      {`Back to top`}
                    </p>
                  ) : (
                    <UpSeatLogo />
                  )}
                </footer>
              </>
            )}
          </main>
        </motion.section>
      </AnimatePresence>
    </NavLayout>
  )
}
