import { useEffect, useState } from 'react'

import { CartSummary } from './CartSummary'
import DefaultLayout from '@/layouts/DefaultLayout'
import HeadlineBar from '@/components/upseat-ui/general/HeadlineBar'
import RouteTransition from '@/components/routeTransition/RouteTransition'
import { Skeleton } from '@/components/ui/skeleton'
import { Stripe } from '@/pages/payment'
import { postBasketConfirmation } from '@/service/Basket/postConfirmation'
import { postIntent } from '@/service/Stripe/postIntent'
import { useAppStore } from '@/context/useAppStore'
import useCallRouteWithDirection from '@/hooks/useCallRouteWithDirection'
import { useCartStore } from '@/context/useCartStore'
import useEventStore from '@/context/useEventStore'
import { useUserTickets } from '@/hooks/useUserTickets'
import { BasketType, useBasketStore } from '@/context/useBasketStore'

export const BookingSummary = () => {
  const { loadUserTickets } = useUserTickets()
  const callRouteWithDirection = useCallRouteWithDirection()
  const { loadingPayment } = useCartStore()
  const { basket, removeBasket, basketType } = useBasketStore()
  const { errors, setAppStore } = useAppStore()
  const { setSelectedSeats } = useEventStore()
  const { seatsPendingPayment } = useCartStore()
  const [timeToReserve, setTimeToReserve] = useState<string>('')
  const [termsModalOpen, setTermsModalOpen] = useState(false)
  const [clientSecret, setClientSecret] = useState('')

  useEffect(() => {
    // if (!basket) {
    //   switch (basketType) {
    //     case BasketType.UPGRADE:
    //       navigate('/seat-map')
    //       break
    //     case BasketType.SWAP:
    //       navigate('/swap-dates')
    //       break
    //     case BasketType.REFUND:
    //       navigate('/tickets')
    //       break
    //   }
    // }

    if (!termsModalOpen) {
      setTimeout(() => {
        setTermsModalOpen(true)
      }, 3000)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (basket) {
      setTimeout(async () => {
        const currentTime = new Date().getTime()
        const expirationTime = new Date(basket?.expires_utc).getTime()
        if (expirationTime < currentTime) {
          await removeBasket()
          callRouteWithDirection('/seat-map', true, 1)
        }

        const timeDifferenceInSeconds = Math.abs(
          (expirationTime - currentTime) / 1000,
        ) // Convert milliseconds to seconds

        // Calculate minutes and seconds
        const minutes = Math.floor(timeDifferenceInSeconds / 60)
        const seconds = Math.floor(timeDifferenceInSeconds % 60)

        // Format result into MM:SS format
        const formattedTime = `${minutes.toString().padStart(2, '0')}:${seconds
          .toString()
          .padStart(2, '0')}`
        setTimeToReserve(formattedTime)
      }, 1000)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timeToReserve])

  useEffect(() => {
    if (basket?.uid) {
      postIntent(basket.uid)
        .then(async (res) => {
          await setClientSecret(res.intent.client_secret)
          await setSelectedSeats([])
        })
        .catch(async (err) => {
          console.log('Stripe Post Intent Error:', err)
          await setAppStore({
            errors: {
              ...errors,
              server: true,
            },
          })
          await removeBasket()
          callRouteWithDirection('/seat-map', true, 1)
        })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const confirmTickets = async () => {
    if (basket)
      postBasketConfirmation(basket.upgrade_uid)
        .then(async () => {
          await removeBasket()
          await setSelectedSeats([])
          await loadUserTickets()

          callRouteWithDirection('/tickets', true, 1)
        })
        .catch((err) => {
          console.log(
            'Error posting basket confirmation:',
            err.response.data.error,
          )
          setAppStore({
            errors: {
              ...errors,
              server: true,
            },
          })
        })
  }

  return (
    <DefaultLayout>
      <RouteTransition>
        <div className="flex flex-col justify-start gap-4">
          <HeadlineBar
            title={(() => {
              switch (basketType) {
                case BasketType.REFUND:
                  return 'Refund Summary'
                default:
                  return 'Booking Summary'
              }
            })()}
            goBack={
              !loadingPayment
                ? () => {
                    switch (basketType) {
                      case BasketType.UPGRADE:
                        callRouteWithDirection('/seat-map', true, 1)
                        break
                      case BasketType.SWAP:
                        callRouteWithDirection('/swap-dates', true, 1)
                        break
                      case BasketType.REFUND:
                        callRouteWithDirection('/tickets', false, 1)
                        break
                    }
                  }
                : undefined
            }
          />
        </div>
        <div
          data-testid="booking-summary"
          className="bg-white min-h-[90svh] overflow-scroll flex flex-col justify-between gap-8 text-purple p-6 pt-4"
        >
          <div>
            {timeToReserve && !loadingPayment ? (
              <div className="flex">
                <p className="bg-purple3 rounded-[20px] text-white font-body px-4 py-1 w-fit">
                  {`Reserved for `}
                  <span className="font-bold">{timeToReserve}</span>
                  {` minutes`}
                </p>
              </div>
            ) : (
              <Skeleton className="bg-purple3/30 w-[65%] h-7" />
            )}
            <div className="text-purple bg-purple7 rounded-[20px] py-6 px-4 my-4">
              <div className="flex flex-col gap-2 justify-between w-full border-b border-grey2 pb-4">
                {basket && (
                  <CartSummary
                    selectedSeats={seatsPendingPayment}
                    basket={basket}
                  />
                )}
              </div>
              {/* <div className="flex justify-between border-b border-grey2 py-4">
                <p>{`Upgrade fee`}</p>
                <p className="flex gap-1 items-center">
                  <span>{`${seatsPendingPayment.length} x`}</span>
                  {`£${basket?.seats
                    .reduce((sum, seat) => {
                      return sum + seat.band.active_discount
                    }, 0)
                    .toFixed(2)}`}
                </p>
              </div> */}
              <div className="flex justify-between font-semibold pt-4">
                <p>{`Total`}</p>
                <p className="flex justify-center items-center gap-2">
                  <span className="bg-purple text-white text-sm font-light rounded-[40px] px-3 py-1">
                    {`Save £` +
                      seatsPendingPayment.reduce((sum, seat) => {
                        return sum + seat.original_price * seat.discount
                      }, 0)}
                  </span>
                  {`£${basket?.total_price.toFixed(2)}`}
                </p>
              </div>
            </div>
            <Stripe
              clientSecret={clientSecret}
              confirmTickets={confirmTickets}
            />
          </div>
        </div>
      </RouteTransition>
    </DefaultLayout>
  )
}
