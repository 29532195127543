import { Booking } from '@/types/booking'
import { Button } from '@/components/ui/button'
import useCallRouteWithDirection from '@/hooks/useCallRouteWithDirection'
import useConditionalRender from '@/hooks/useConditionalRender'

type TicketInfoProps = {
  ticketInfo: Booking
  upgraded?: boolean
  embeddedInTicket?: boolean
  bookingSearch?: boolean
  handleFlip?: () => void
}

export const TicketInfo = ({
  ticketInfo,
  upgraded,
  embeddedInTicket,
  bookingSearch,
  handleFlip,
}: TicketInfoProps) => {
  const callRouteWithDirection = useCallRouteWithDirection()

  return (
    <div
      className={`
      ${
        embeddedInTicket ? `rounded-[50px_50px_0_0]` : `rounded-containers`
      } show-card relative px-4 flex flex-col gap-4 w-full`}
      onClick={handleFlip}
    >
      <div className="info-container items-center flex justify-center text-center gap-4">
        {useConditionalRender(
          !bookingSearch,
          <div className="info flex overflow-hidden flex-col justify-center text-purple">
            {upgraded ? null : (
              <div className="flex flex-col justify-center items-center w-max rounded-full text-sm leading-none py-2 px-4 my-1 bg-red text-white">
                <span>{`AVAILABLE now`}</span>
              </div>
            )}
            <div className="flex flex-col overflow-hidden justify-start">
              <p
                className="text-[18px] leading-none capitalize font-bold text-wrap"
                id="ticket-state-btn"
              >
                {ticketInfo.event_name.toLowerCase()}
              </p>
            </div>
          </div>,
        )}
      </div>

      {!embeddedInTicket && !bookingSearch ? (
        upgraded ? (
          <Button
            variant="primaryDark"
            className="mr-2"
            onClick={() => callRouteWithDirection('/tickets', false, 2)}
          >
            {`View Tickets`}
          </Button>
        ) : (
          <Button
            variant="primaryDark"
            className="mr-2"
            onClick={() => callRouteWithDirection('/seat-map', false, 2)}
          >
            {`Choose Seats`}
          </Button>
        )
      ) : null}
    </div>
  )
}
