import { Plus, Star } from 'lucide-react'
import { filterResults, isCurrentSeat, selectSeat } from '../..'

import { Order } from '@/types/booking'
/* eslint-disable max-lines */
import { Seat } from '@/types'
import { motion } from 'framer-motion'
import useEventStore from '@/context/useEventStore'

interface StallsCentralProps {
  order: Order | undefined
  discountRange: number[]
  selectedSeats: Seat[]
  setSelectedSeats: (selectedSeats: Seat[]) => void
  setSeatAsideWarning: (value: React.SetStateAction<boolean>) => void
  mapSeatsByRow: (
    mapSeats: Seat[],
    location?: string,
  ) => { row: string; seats: Seat[] }[]
}

export const StallsCentral = ({
  order,
  discountRange,
  selectedSeats,
  setSelectedSeats,
  setSeatAsideWarning,
  mapSeatsByRow,
}: StallsCentralProps) => {
  const { mapSeats } = useEventStore()

  return (
    <div>
      {mapSeats &&
        mapSeatsByRow(mapSeats, 'Stalls')
          .sort((a, b) => (a.row === 'BB' ? -1 : b.row === 'BB' ? 1 : 0))
          .map((row) => {
            return (
              <div
                className={`flex items-center my-[6px] ${row.row}`}
                key={row.row}
              >
                <div
                  className={`w-full relative flex ${row.row === 'N' ? 'justify-start left-4' : row.row === 'H' ? 'justify-start left-[22px]' : row.row === 'G' ? 'justify-start left-[30px]' : 'justify-center'}  gap-[3px]`}
                >
                  {row.seats.reverse().map((seat, index) => {
                    return (
                      <div
                        key={seat.seat_id}
                        onClick={() =>
                          selectSeat(
                            seat,
                            selectedSeats,
                            setSelectedSeats,
                            setSeatAsideWarning,
                          )
                        }
                        style={{
                          width: `12px`,
                          height: `12px`,
                        }}
                        className={`seat flex items-center justify-center ${row.row + seat.number}
                  ${selectedSeats.includes(seat) && 'selected-seat'}
                  ${
                    isCurrentSeat(
                      order,
                      seat.row + seat.number.toString(),
                      seat.location,
                    ) && 'current-seat pointer-events-none'
                  }
                  ${
                    !selectedSeats.includes(seat) &&
                    !isCurrentSeat(
                      order,
                      seat.row + seat.number.toString(),
                      seat.location,
                    ) &&
                    filterResults(seat.tier, discountRange)
                  }
                  ${
                    !selectedSeats.includes(seat) &&
                    !isCurrentSeat(
                      order,
                      seat.row + seat.number.toString(),
                      seat.location,
                    ) &&
                    order?.tickets.length === selectedSeats.length &&
                    'opacity-[.2] pointer-events-none'
                  }
                  `}
                      >
                        <motion.div
                          initial={{ scale: 0 }}
                          animate={{
                            scale: isCurrentSeat(
                              order,
                              seat.row + seat.number.toString(),
                              seat.location,
                            )
                              ? [1, 2, 2, 1, 1]
                              : 1,
                            rotate: isCurrentSeat(
                              order,
                              seat.row + seat.number.toString(),
                              seat.location,
                            )
                              ? [0, 0, -270, -270, 0]
                              : 0,
                            borderRadius: isCurrentSeat(
                              order,
                              seat.row + seat.number.toString(),
                              seat.location,
                            )
                              ? ['20%', '20%', '50%', '50%', '20%']
                              : '0%',
                          }}
                        >
                          <span
                            style={{
                              backgroundColor: seat.tier
                                ? 'var(--tier' + seat.tier + ')'
                                : 'grey',
                            }}
                            className={`relative flex items-center justify-center h-[12px] w-[12px] block rounded-[4px]
                    ${selectedSeats.includes(seat) && `!bg-blue`}
                    ${
                      isCurrentSeat(
                        order,
                        seat.row + seat.number.toString(),
                        seat.location,
                      ) && `!bg-[white] border-[2px] border-[black]`
                    }`}
                          >
                            {isCurrentSeat(
                              order,
                              seat.row + seat.number.toString(),
                              seat.location,
                            ) && (
                              <Plus
                                color="black"
                                style={{
                                  rotate: '45deg',
                                  position: 'absolute',
                                }}
                                width={18}
                                height={20}
                              />
                            )}
                            {selectedSeats.includes(seat) && (
                              <Star color="white" fill="white" size={8} />
                            )}
                          </span>
                        </motion.div>
                      </div>
                    )
                  })}
                </div>
              </div>
            )
          })}

      {mapSeats &&
        mapSeatsByRow(mapSeats, 'Standing Room').map((row) => {
          return (
            <div
              className={`w-fit relative left-[6.5rem] my-2 flex flex-col
                items-center p-1 border border-grey3 rounded-[10px] ${row.row}`}
              key={row.row}
            >
              <div className="relative flex justify-end gap-[3px]">
                {row.seats.reverse().map((seat) => {
                  return (
                    <div
                      key={seat.seat_id}
                      onClick={() =>
                        selectSeat(
                          seat,
                          selectedSeats,
                          setSelectedSeats,
                          setSeatAsideWarning,
                        )
                      }
                      style={{
                        width: `12px`,
                        height: `12px`,
                      }}
                      className={`seat flex items-center justify-center ${row.row + seat.number}
                  ${selectedSeats.includes(seat) && 'selected-seat'}
                  ${
                    isCurrentSeat(
                      order,
                      seat.row + seat.number.toString(),
                      seat.location,
                    ) && 'current-seat pointer-events-none'
                  }
                  ${
                    !selectedSeats.includes(seat) &&
                    !isCurrentSeat(
                      order,
                      seat.row + seat.number.toString(),
                      seat.location,
                    ) &&
                    filterResults(seat.tier, discountRange)
                  }
                  ${
                    !selectedSeats.includes(seat) &&
                    !isCurrentSeat(
                      order,
                      seat.row + seat.number.toString(),
                      seat.location,
                    ) &&
                    order?.tickets.length === selectedSeats.length &&
                    'opacity-[.2] pointer-events-none'
                  }
                  `}
                    >
                      <motion.div
                        initial={{ scale: 0 }}
                        animate={{
                          scale: isCurrentSeat(
                            order,
                            seat.row + seat.number.toString(),
                            seat.location,
                          )
                            ? [1, 2, 2, 1, 1]
                            : 1,
                          rotate: isCurrentSeat(
                            order,
                            seat.row + seat.number.toString(),
                            seat.location,
                          )
                            ? [0, 0, -270, -270, 0]
                            : 0,
                          borderRadius: isCurrentSeat(
                            order,
                            seat.row + seat.number.toString(),
                            seat.location,
                          )
                            ? ['20%', '20%', '50%', '50%', '20%']
                            : '0%',
                        }}
                      >
                        <span
                          style={{
                            backgroundColor: seat.tier
                              ? 'var(--tier' + seat.tier + ')'
                              : 'grey',
                          }}
                          className={`relative flex items-center justify-center h-[12px] w-[12px] block rounded-[4px]
                    ${selectedSeats.includes(seat) && `!bg-blue`}
                    ${
                      isCurrentSeat(
                        order,
                        seat.row + seat.number.toString(),
                        seat.location,
                      ) && `!bg-[white] border-[2px] border-[black]`
                    }`}
                        >
                          {isCurrentSeat(
                            order,
                            seat.row + seat.number.toString(),
                            seat.location,
                          ) && (
                            <Plus
                              color="black"
                              style={{
                                rotate: '45deg',
                                position: 'absolute',
                              }}
                              width={18}
                              height={20}
                            />
                          )}
                          {selectedSeats.includes(seat) && (
                            <Star color="white" fill="white" size={8} />
                          )}
                        </span>
                      </motion.div>
                    </div>
                  )
                })}
              </div>
            </div>
          )
        })}
      <p className="text-center text-[11px] mt-2">{`Ground Floor`}</p>
    </div>
  )
}
