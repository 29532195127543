import { BookingDetails } from '@/components/upseat-ui/seatMap/BookingDetails/index'
import DefaultLayout from '@/layouts/DefaultLayout'
import HeadlineBar from '@/components/upseat-ui/general/HeadlineBar'
import RouteTransition from '@/components/routeTransition/RouteTransition'
import useCallRouteWithDirection from '@/hooks/useCallRouteWithDirection'
import useExistingBookingStore from '@/context/useExistingBookingStore'
import { useNavigate } from 'react-router-dom'
import { Calendar } from '@/components/ui/calendar'
import { useEffect, useState } from 'react'
import {
  Drawer,
  DrawerClose,
  DrawerContent,
  DrawerDescription,
  DrawerFooter,
  DrawerHeader,
  DrawerTitle,
} from '@/components/ui/drawer'
import { Plus } from 'lucide-react'
import { Button } from '@/components/ui/button'
import {
  formatDateTime,
  getLastDayOfMonth,
  getFirstDayOfMonth,
  timeString,
  getNextDay,
} from '@/utils'
import { BasketType, useBasketStore } from '@/context/useBasketStore'
import { swapSeats } from '@/service/Seats/swapSeats'
import LoadingIndicator from '@/components/upseat-ui/seatMap/LoadingIndicator'
import { motion } from 'framer-motion'

interface SwapDatesProps {
  instance_uid: string
  date: string
  available: boolean
  direct: boolean
}

export const SwapDates = () => {
  const callRouteWithDirection = useCallRouteWithDirection()
  const navigate = useNavigate()
  const { setBasketType } = useBasketStore()
  const { order, booking } = useExistingBookingStore()
  const [loading, setLoading] = useState(true)
  const [availableDates, setAvailableDates] = useState<Date[]>([])
  const [selectedDate, setSelectedDate] = useState<Date | undefined>()
  const [currentMonth, setCurrentMonth] = useState<Date | undefined>(undefined)
  const formattedDateTime = formatDateTime(selectedDate as Date)
  const date =
    formattedDateTime !== 'Invalid Date' ? formattedDateTime.date : undefined

  const goBack = async () => {
    callRouteWithDirection('/tickets', true, 1)
  }

  const fetchAvailableDates = () => {
    const eventDate = booking?.start_time ? new Date(booking.start_time) : null
    const monthDate = currentMonth ? new Date(currentMonth) : null

    swapSeats(
      order?.uid as string,

      // Determine the start date
      monthDate && eventDate && eventDate < monthDate
        ? getFirstDayOfMonth(monthDate)
        : getNextDay(eventDate as Date),
      // Determine the end date
      (() => {
        return getLastDayOfMonth(monthDate ?? eventDate!)
      })(),
    )
      .then(async (res: SwapDatesProps[]) => {
        if (!res) {
          setLoading(false)
          return
        }
        // get dates from response if available
        const availableDatesArray = await res
          .filter((date) => date.available)
          .map((date) => new Date(date.date))

        setAvailableDates(availableDatesArray)
      })
      .catch((err) => {
        console.error(err)
      })
      .finally(() => {
        setLoading(false)
      })
  }

  useEffect(() => {
    if (!booking) {
      navigate('/tickets')
    }
    fetchAvailableDates()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentMonth])

  return (
    <DefaultLayout>
      <RouteTransition>
        <div className="flex flex-col justify-start">
          <HeadlineBar
            title={
              <div className="flex items-center capitalize">{`swap your dates`}</div>
            }
            goBack={goBack}
          />
          <div className="select-seats w-full">
            <div
              data-testid="swap-dates"
              className={`bg-white text-purple h-[90vh] pb-[20vh] flex flex-col`}
            >
              {booking && (
                <>
                  <BookingDetails bookingData={booking} />
                  {loading ? (
                    <div
                      data-testid="loading"
                      className="flex justify-center items-center h-[40vh]"
                    >
                      <LoadingIndicator dark />
                    </div>
                  ) : (
                    <>
                      <motion.div
                        initial={{
                          opacity: 0,
                          filter: 'blur(5px)',
                        }}
                        animate={{ opacity: 1, filter: 'blur(0)' }}
                      >
                        <Calendar
                          mode="single"
                          eventStartDate={booking.start_time}
                          highlightedDates={availableDates}
                          currentMonth={currentMonth}
                          setCurrentMonth={setCurrentMonth}
                          selected={selectedDate}
                          onSelect={setSelectedDate}
                          className="rounded-md border"
                        />
                      </motion.div>
                      <div className="bg-purple2 text-white rounded-[20px] my-2 mx-12 p-4">
                        <p>{`Only showing dates with equivalent seats available. `}</p>
                      </div>
                    </>
                  )}

                  <Drawer
                    open={!!date}
                    data-testid="drawer"
                    onClose={() => setSelectedDate(undefined)}
                  >
                    <DrawerContent className="bg-purple pb-4 z-[500]">
                      <div className="mx-auto w-full max-w-sm">
                        {availableDates.find(
                          (availableDate) =>
                            availableDate.toISOString().split('T')[0] ===
                            selectedDate?.toISOString().split('T')[0],
                        ) ? (
                          <>
                            <DrawerHeader className="relative flex justify-between items-start p-6 pt-8">
                              <div className="w-full flex flex-col gap-6">
                                <DrawerTitle className="text-sm">{`Available Seats`}</DrawerTitle>
                                <div className="flex flex-col gap-2">
                                  <div className="w-full flex justify-between">
                                    <DrawerDescription>
                                      {date}
                                    </DrawerDescription>
                                    <DrawerDescription>
                                      <>
                                        {timeString(
                                          availableDates.find(
                                            (availableDate) =>
                                              availableDate
                                                .toISOString()
                                                .split('T')[0] ===
                                              selectedDate
                                                ?.toISOString()
                                                .split('T')[0],
                                          ) as Date,
                                        )}
                                      </>
                                    </DrawerDescription>
                                  </div>
                                  <div className="w-full flex justify-between">
                                    <DrawerDescription>{`Stalls`}</DrawerDescription>
                                    <DrawerDescription>{`Row D, 5-6`}</DrawerDescription>
                                  </div>
                                </div>
                              </div>
                              <DrawerClose asChild>
                                <Button
                                  variant="primaryDark"
                                  className="absolute top-0 right-4 p-0"
                                >
                                  <Plus className="z-10 rotate-45 font-light w-8" />
                                </Button>
                              </DrawerClose>
                            </DrawerHeader>
                            <DrawerFooter className="py-0">
                              <DrawerClose
                                asChild
                                onClick={() => {
                                  setBasketType(BasketType.SWAP)
                                  callRouteWithDirection(
                                    '/booking-summary',
                                    false,
                                    1,
                                  )
                                }}
                              >
                                <Button variant="primaryYellow">{`Select`}</Button>
                              </DrawerClose>
                            </DrawerFooter>
                          </>
                        ) : (
                          <div className="flex justify-center items-center h-[30vh]">
                            <p className="text-white">{`No seats available for this date.`}</p>
                          </div>
                        )}
                      </div>
                    </DrawerContent>
                  </Drawer>
                </>
              )}
            </div>
          </div>
        </div>
      </RouteTransition>
    </DefaultLayout>
  )
}
