import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@/components/ui/select'
import { SubmitHandler, useForm } from 'react-hook-form'
import {
  personalDetailsFormSchema,
  personalDetailsFormSchemaType,
} from '@/lib/validation-schemas/personalDetailsFormSchema'

import HeadlineBar from '@/components/upseat-ui/general/HeadlineBar'
import RouteTransition from '@/components/routeTransition/RouteTransition'
import { Switch } from '@/components/ui/switch'
import { useAppStore } from '@/context/useAppStore'
import useCallRouteWithDirection from '@/hooks/useCallRouteWithDirection'
import { zodResolver } from '@hookform/resolvers/zod'
import DefaultLayout from '@/layouts/DefaultLayout'

export const Account = () => {
  const callRouteWithDirection = useCallRouteWithDirection()
  const { alerts, currency, setAppStore } = useAppStore()

  const {
    formState: { errors },
  } = useForm<personalDetailsFormSchemaType>({
    resolver: zodResolver(personalDetailsFormSchema),
  })

  const onSubmit: SubmitHandler<personalDetailsFormSchemaType> = (data) => {
    console.log('DATA SENT ->', data)
  }

  // const deleteAccount = () => {
  //   deleteUser().then((res) => {
  //     console.log(res, 'account deleted')
  //   })

  //   callRouteWithDirection('/tickets', true, 1)
  // }

  return (
    <DefaultLayout>
      <RouteTransition>
        <div className="flex flex-col justify-start gap-4">
          <HeadlineBar
            title={`account`}
            goBack={() => callRouteWithDirection('/tickets', true, 3)}
          />
        </div>
        <main
          className="bg-white overflow-scroll flex flex-col gap-4 text-purple h-[90svh] p-4 py-6"
          data-testid="account"
        >
          <div data-testid="currency-select">
            <p className="uppercase text-[12px] pl-2">{'currency'}</p>
            <Select
              defaultValue={currency}
              onValueChange={(e) => setAppStore({ currency: e })}
              disabled
            >
              <SelectTrigger className="w-full text-purple">
                <SelectValue placeholder={`£ Pounds Sterling`} />
              </SelectTrigger>
              <SelectContent className="w-[90vw] text-purple border-none py-2">
                <SelectGroup>
                  <SelectItem value="GBP">{`£ Pounds Sterling`}</SelectItem>
                  <SelectItem value="USD">{`$ US Dollar`}</SelectItem>
                  <SelectItem value="EUR">{`€ Euro`}</SelectItem>
                </SelectGroup>
              </SelectContent>
            </Select>
          </div>
          <span className="border border-grey2 my-4" />
          <div>
            <p className="uppercase text-[12px]">{'upgrade alerts'}</p>
            <div className="flex items-center justify-between my-4 mb-0 font-light">
              <p className="text-sm font-body">{`Text Alerts (free of charge)`}</p>
              <Switch
                disabled
                defaultChecked={alerts.text}
                onClick={() =>
                  setAppStore({ alerts: { ...alerts, text: !alerts.text } })
                }
                data-testid="text-alert-switch"
              />
            </div>
            <div className="flex items-center justify-between mt-4 mb-0 font-light">
              <p className="text-sm font-body">{`Email Alerts`}</p>
              <Switch
                disabled
                defaultChecked={alerts.email}
                onClick={() =>
                  setAppStore({ alerts: { ...alerts, email: !alerts.email } })
                }
                data-testid="email-alert-switch"
              />
            </div>
          </div>
          <span className="border border-grey2 my-4" />
          <div>
            <p className="uppercase text-[12px]">{'your orders'}</p>
          </div>
          {/* <span className="border border-grey2 mt-4 mb-0" /> */}

          {/* <AlertDialog>
            <AlertDialogTrigger className="flex justify-start">
              <Button
                variant="textOnly"
                className="text-purple3 font-light p-0"
              >
                {`Delete your account`}
              </Button>
            </AlertDialogTrigger>
            <AlertDialogContent>
              <AlertDialogHeader>
                <AlertDialogTitle className="font-body font-semibold">
                  {`Are you sure?`}
                </AlertDialogTitle>
                <AlertDialogDescription>
                  {`Your account will be permanently deleted and all data linked to your account will be removed.`}
                </AlertDialogDescription>
              </AlertDialogHeader>
              <AlertDialogFooter>
                <AlertDialogCancel className="mb-2">
                  {`No thanks`}
                </AlertDialogCancel>
                <AlertDialogAction
                  onClick={() => {
                    deleteAccount()
                    unsetUser()
                    cleanTokenFromLocalStorage()
                    callRouteWithDirection('/sync', false, 1)
                  }}
                  className="bg-red mb-2"
                >{`I want to delete my account`}</AlertDialogAction>
              </AlertDialogFooter>
            </AlertDialogContent>
          </AlertDialog> */}
        </main>
      </RouteTransition>
    </DefaultLayout>
  )
}
