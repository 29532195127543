/* eslint-disable max-lines */
import { Controller, SubmitHandler, useForm } from 'react-hook-form'
import { Eye, EyeOff, Plus } from 'lucide-react'
import {
  signUpValidationSchema,
  signUpValidationSchemaType,
} from '@/lib/validation-schemas/signupFormSchema'
import { useEffect, useState } from 'react'

import { Button } from '@/components/ui/button'
import { CountryCode } from '@/types'
import { Input } from '@/components/ui/input'
import LoadingIndicator from '../../seatMap/LoadingIndicator'
import { Modal } from '../../modals'
import PublicLayout from '@/layouts/PublicLayout'
import RouteTransition from '@/components/routeTransition/RouteTransition'
import { WarningIconWhite } from '@/assets/warningIconWhite'
import { postLogin } from '@/service/Login/postLogin'
import { registerUser } from '@/service/User/registerUser'
import { syncBooking } from '@/service/Booking/syncBooking'
import { useAppStore } from '@/context/useAppStore'
import useCallRouteWithDirection from '@/hooks/useCallRouteWithDirection'
import useFocus from '@/hooks/useFocus'
import { useSearchParams } from 'react-router-dom'
import { useUserStore } from '@/context/useUserStore'
import { zodResolver } from '@hookform/resolvers/zod'

interface SignUpFormProps {
  countryCode: CountryCode
  setCountryCode: (value: CountryCode) => void
  setSignUpForm: (value: boolean) => void
  setPhoneAlertForm: (value: boolean) => void
}

export const SignUpForm = ({
  countryCode,
  setCountryCode,
}: SignUpFormProps) => {
  const [searchParams] = useSearchParams()
  const firstName = searchParams.get('firstName')
  const lastName = searchParams.get('lastName')
  const email = searchParams.get('email')

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<signUpValidationSchemaType>({
    resolver: zodResolver(signUpValidationSchema),
  })

  const { user, setUser } = useUserStore()
  const { setAppStore } = useAppStore()
  const callRouteWithDirection = useCallRouteWithDirection()
  const [error, setError] = useState<string | null>()
  const [loadingSignUp, setLoadingSignUp] = useState<boolean>(false)
  const [showPassword, setShowPassword] = useState<boolean>(false)
  const [showConfirmPassword, setShowConfirmPassword] = useState<boolean>(false)
  const [infoModal, setInfoModal] = useState<boolean>(false)
  const [inputRef, isInputFocused] = useFocus()

  useEffect(() => {
    setError(null)
  }, [control])

  useEffect(() => {
    if (isInputFocused) {
      setInfoModal(true)
    }
  }, [isInputFocused])

  const onSubmit: SubmitHandler<signUpValidationSchemaType> = async (data) => {
    setLoadingSignUp(true)
    await registerUser(
      data.firstName,
      data.lastName,
      '',
      data.email,
      data.password,
    )
      .then(async (res) => {
        await postLogin(data.email, data.password)
          .then(async (loginResponse) => {
            await localStorage.setItem(
              'access_token',
              loginResponse.access_token,
            )
            await localStorage.setItem(
              'refresh_token',
              loginResponse.refresh_token,
            )
            await localStorage.setItem('user_onboarding', 'true')

            setAppStore({
              loading: { app: true, initial: false },
            })
            setUser({
              id: res?.data.uid,
              username: res?.data.email.split('@')[0],
              firstname: res?.data.first_name,
              lastname: res?.data.last_name,
              email: res?.data.email,
              phoneNumber: res?.data.phone_number ?? '',
              countryCode: {
                acronym: countryCode.acronym,
                code: countryCode.code,
                digits: countryCode.digits,
              },
            })
            syncBooking()
              .then()
              .catch((error) => {
                console.log('Sync Booking Error:', error)
              })
              .finally(() => {
                callRouteWithDirection('/tickets', true, 1)
                setAppStore({
                  loading: { app: false, initial: false },
                })
              })
          })
          .catch((err) => {
            setLoadingSignUp(false)
            setError(err.response.data.error)
          })
        return true
      })
      .catch((err) => {
        console.log('Error:', err)
        setError(
          err.response.data.error.msg.password ??
            err.response.data.error.msg ??
            err.response.data.error ??
            'Beta access only for staff members',
        )
      })
      .finally(() => {
        setLoadingSignUp(false)
      })
  }

  return (
    <PublicLayout>
      <RouteTransition>
        <main
          data-testid="sign-up"
          className="h-full flex flex-col justify-end pb-[15vh]"
        >
          <form
            onSubmit={handleSubmit(onSubmit)}
            className="overflow-scroll flex flex-col gap-3 text-purple p-4 py-6"
            data-testid="sign-up-form"
            autoComplete={'off'}
            id="sign-up-form"
          >
            <h3 className="text-h3 text-white my-2">{`the show start here`}</h3>
            <div className="flex gap-2">
              <Controller
                name="firstName"
                control={control}
                defaultValue={firstName ?? ''}
                render={({ field }) => (
                  <div className="flex flex-col w-[50%]">
                    <Input
                      className={`${
                        errors.firstName?.message &&
                        'border-red placeholder:text-red'
                      } py-4`}
                      placeholder="First Name"
                      id="signup-form-firstname"
                      variant="dark"
                      {...field}
                      onChange={(e) => {
                        field.onChange(e)
                        setError(null)
                      }}
                    />
                    {errors.firstName && (
                      <p className="text-[12px] text-pretty pl-2 text-red pt-1">
                        {errors.firstName.message}
                      </p>
                    )}
                  </div>
                )}
              />

              <Controller
                name="lastName"
                control={control}
                defaultValue={lastName ?? ''}
                render={({ field }) => (
                  <div className="flex flex-col w-[50%]">
                    <Input
                      className={`${
                        errors.lastName?.message &&
                        'border-red placeholder:text-red'
                      } py-4`}
                      id="signup-form-lastname"
                      variant="dark"
                      placeholder="Last Name"
                      {...field}
                      onChange={(e) => {
                        field.onChange(e)
                        setError(null)
                      }}
                    />

                    {errors.lastName && (
                      <p className="text-[12px] text-pretty pl-2 text-red pt-1">
                        {errors.lastName.message}
                      </p>
                    )}
                  </div>
                )}
              />
            </div>

            <Controller
              name="email"
              control={control}
              defaultValue={email ?? ''}
              render={({ field }) => (
                <div className="flex flex-col">
                  <Input
                    className={`${
                      errors.email?.message && 'border-red placeholder:text-red'
                    } py-4`}
                    id="signup-form-email"
                    variant="dark"
                    placeholder="Email Address"
                    {...field}
                    ref={(e) => {
                      field.ref(e)
                      ;(
                        inputRef as React.MutableRefObject<HTMLInputElement | null>
                      ).current = e
                    }}
                    onChange={(e) => {
                      const lowercaseValue = e.target.value.toLowerCase()
                      field.onChange(lowercaseValue)
                      setError(null)
                    }}
                  />

                  {errors.email && (
                    <p className="text-[12px] text-pretty pl-2 text-red pt-1">
                      {errors.email.message}
                    </p>
                  )}

                  {!errors.email && !infoModal && (
                    <p className="text-[12px] text-nowrap pr-2 pt-1 text-purple5 text-end">
                      {`Enter the same email used to book your tickets`}
                    </p>
                  )}
                </div>
              )}
            />

            {infoModal && (
              <>
                <Modal
                  modalOpen={infoModal}
                  content={
                    <div className="relative flex flex-col bg-purple2 rounded-[20px] p-4 pt-3 pr-3 flex gap-2">
                      <div className="relative flex gap-2 items-center text-white">
                        <span className="pt-[2px]">
                          <WarningIconWhite />
                        </span>
                        <p> {`Check your email`}</p>
                        <Plus
                          width={24}
                          height={24}
                          color="white"
                          className="absolute right-0 z-10 rotate-45 text-purple font-light"
                          onClick={() => setInfoModal(false)}
                        />
                      </div>
                      <p className="w-fit text-white text-sm leading-snug text-pretty pr-8">
                        {`Sign up with the same email address used to book your theatre tickets.`}
                      </p>
                      <div className="w-full flex justify-end">
                        <Button
                          className="py-1 px-3 w-fit"
                          variant={'secondaryWhite'}
                          onClick={() => setInfoModal(false)}
                        >
                          {`Got it, let’s go`}
                        </Button>
                      </div>
                    </div>
                  }
                />
              </>
            )}

            <Controller
              name="password"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <div className="flex flex-col">
                  <div className="relative">
                    <Input
                      className={`${
                        errors.password?.message &&
                        'border-red placeholder:text-red'
                      } py-4 `}
                      id="signup-form-password"
                      variant="dark"
                      type={showPassword ? 'text' : 'password'}
                      placeholder="Create Password"
                      {...field}
                      onChange={(e) => {
                        field.onChange(e)
                        setError(null)
                      }}
                    />
                    <label
                      onClick={() => setShowPassword(!showPassword)}
                      className="absolute top-5 right-4 bg-gray-300 hover:bg-gray-400 rounded text-sm text-gray-600 font-mono cursor-pointer"
                    >
                      {showPassword ? (
                        <Eye
                          color={`${errors.password ? '#ef6363' : '#CDC3FB'}`}
                          size={16}
                        />
                      ) : (
                        <EyeOff
                          color={`${errors.password ? '#ef6363' : '#CDC3FB'}`}
                          size={16}
                        />
                      )}
                    </label>
                  </div>

                  {errors.password && (
                    <p className="text-[12px] text-pretty pl-2 text-red pt-1">
                      {errors.password.message}
                    </p>
                  )}

                  {!errors.password && (
                    <p className="text-[12px] text-nowrap pr-2 pt-1 text-purple5 text-end">
                      {`Must be at least 8 characters `}
                    </p>
                  )}
                </div>
              )}
            />

            <Controller
              name="confirmPassword"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <div className="flex flex-col">
                  <div className="relative">
                    <Input
                      className={`${
                        errors.confirmPassword?.message &&
                        'border-red placeholder:text-red'
                      } py-4 `}
                      id="signup-form-confirm-password"
                      variant="dark"
                      type={showConfirmPassword ? 'text' : 'password'}
                      placeholder="Confirm Password"
                      {...field}
                      onChange={(e) => {
                        field.onChange(e)
                        setError(null)
                      }}
                    />

                    <label
                      onClick={() =>
                        setShowConfirmPassword(!showConfirmPassword)
                      }
                      className="absolute top-5 right-4 bg-gray-300 hover:bg-gray-400 rounded text-sm text-gray-600 font-mono cursor-pointer"
                    >
                      {showConfirmPassword ? (
                        <Eye
                          color={`${errors.confirmPassword ? '#ef6363' : '#CDC3FB'}`}
                          size={16}
                        />
                      ) : (
                        <EyeOff
                          color={`${errors.confirmPassword ? '#ef6363' : '#CDC3FB'}`}
                          size={16}
                        />
                      )}
                    </label>
                  </div>

                  {errors.confirmPassword && (
                    <p className="text-[12px] text-pretty pl-2 text-red pt-1">
                      {errors.confirmPassword.message}
                    </p>
                  )}
                </div>
              )}
            />

            <div className="">
              {!loadingSignUp ? (
                <Button
                  type="submit"
                  variant="primaryYellow"
                  data-testid="sign-up-button"
                  id="sign-up-form-button"
                  className="w-full "
                >{`Create my account`}</Button>
              ) : (
                <div className="py-4 " data-testid="loading-indicator">
                  <LoadingIndicator dark />
                </div>
              )}

              {error && (
                <p className="text-[12px] text-pretty text-center pr-2 pt-2 text-red">
                  {error}
                </p>
              )}
            </div>

            <div>
              <p className="text-white text-center text-[12px] leading-normal text-balance">
                {`By signing up you agree to UpSeat’s `}
              </p>
              <p className="text-center text-white text-[12px] leading-normal text-balance">
                <a
                  className="text-purple5"
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://www.notion.so/upseat/Our-legals-273016cae1d24dd4802f65e788c47631"
                >{`Terms & Conditions`}</a>
                {` and `}
                <a
                  className="text-purple5"
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://www.notion.so/upseat/Our-legals-273016cae1d24dd4802f65e788c47631"
                >{`Privacy Policy`}</a>
              </p>
            </div>

            <div className="flex text-center items-center justify-center gap-2 my-2">
              <p className="text-white">{`Already have an account?`}</p>
              <Button
                className="text-sm"
                variant="secondaryWhite"
                size={'small'}
                onClick={() => callRouteWithDirection('/login', false, 2)}
              >
                {`Login`}
              </Button>
            </div>
          </form>
        </main>
      </RouteTransition>
    </PublicLayout>
  )
}
