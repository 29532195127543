import {
  Drawer,
  DrawerClose,
  DrawerContent,
  DrawerDescription,
  DrawerFooter,
  DrawerHeader,
  DrawerTitle,
} from '@/components/ui/drawer'
import { Button } from '@/components/ui/button'
import { Plus } from 'lucide-react'
import useCallRouteWithDirection from '@/hooks/useCallRouteWithDirection'
import { BasketType, useBasketStore } from '@/context/useBasketStore'

interface RefundTicketProps {
  refundOpen: boolean
  setRefundOpen: (value: boolean) => void
}

export const RefundTicket = ({
  refundOpen,
  setRefundOpen,
}: RefundTicketProps) => {
  const { setBasketType } = useBasketStore()
  const callRouteWithDirection = useCallRouteWithDirection()

  return (
    <Drawer open={refundOpen} onClose={() => setRefundOpen(!refundOpen)}>
      <DrawerContent>
        <div className="mx-auto w-full max-w-sm">
          <DrawerHeader className="relative flex justify-between">
            <div className="flex flex-col gap-3">
              <DrawerTitle>{`Need a refund for your tickets?`}</DrawerTitle>
              <DrawerDescription>{``}</DrawerDescription>
            </div>
            <DrawerClose asChild>
              <Button
                role="button"
                variant="primaryDark"
                className="absolute top-0 right-0 py-0 px-4"
              >
                <Plus
                  width={32}
                  height={32}
                  className="z-10 rotate-45 font-light"
                />
              </Button>
            </DrawerClose>
          </DrawerHeader>
          <DrawerFooter>
            <DrawerClose asChild>
              <Button
                variant="primaryYellow"
                onClick={() => {
                  setBasketType(BasketType.REFUND)
                  callRouteWithDirection('/booking-summary', false, 1)
                }}
              >{`Confirm`}</Button>
            </DrawerClose>
          </DrawerFooter>
        </div>
      </DrawerContent>
    </Drawer>
  )
}
