import { AnimatePresence, easeIn, motion } from 'framer-motion'
import { useEffect, useState } from 'react'

import DefaultLayout from '@/layouts/DefaultLayout'
import NavLayout from '@/layouts/NavLayout'
import { NavigationHeader } from '@/components/upseat-ui/wallet/NavigationHeader'
import { OnboardingTicket } from '@/components/upseat-ui/wallet/OnboardingTicket'
import SeatsLoader from '@/components/upseat-ui/seatMap/SeatsLoader'
import SeatsNotFound from '@/components/upseat-ui/seatMap/SeatsNotFound'
import { SideMenu } from '@/components/upseat-ui/general/SideMenu'
import { TicketStack } from '@/components/upseat-ui/tickets'
import { UpSeatLogo } from '@/assets/logos/upseat_logo'
import { syncBooking } from '@/service/Booking/syncBooking'
import { transition } from '@/utils'
import { useAppStore } from '@/context/useAppStore'
import useConditionalRender from '@/hooks/useConditionalRender'
import useEventStore from '@/context/useEventStore'
import { useTicketsStore } from '@/context/useTicketsStore'
import { useUserStore } from '@/context/useUserStore'
import { useSeatMap } from '@/hooks/useSeatMap'

export const Wallet = () => {
  const { user } = useUserStore()
  const { mapSeats, seatMapOn } = useEventStore()
  const { wallet } = useTicketsStore()
  const { loading, ui } = useAppStore()
  const { searchingSeats } = useSeatMap()
  const [onboardingTicket, setOnboardingTicket] = useState<boolean>(false)
  const [activeItem, setActiveItem] = useState<number>(0)

  useEffect(() => {
    if (localStorage.getItem('user_onboarding')) {
      setOnboardingTicket(true)
    }

    syncBooking()
      .then()
      .catch((error) => {
        console.error('Sync Booking Error:', error)
      })
  }, [])

  return (
    <div data-testid="tickets" id="tickets" className="w-full">
      {useConditionalRender(
        !loading.app,
        <>
          <NavLayout overflowHidden>
            <SideMenu />
            <div
              className={`flex flex-col justify-start items-center h-lvh ${ui.sideMenu ? 'opacity-20 transition-transform duration-200' : ''}`}
            >
              {onboardingTicket ? (
                <div className="py-4 mb-2">
                  <span className="[&>svg]:w-[28px] [&>svg]:h-[28px]">
                    <UpSeatLogo />
                  </span>
                </div>
              ) : (
                <NavigationHeader
                  activeItem={activeItem}
                  setActiveItem={setActiveItem}
                  searchingSeats={searchingSeats}
                />
              )}

              {useConditionalRender(
                !!user && !searchingSeats,
                <motion.ul
                  className={`w-full flex flex-col items-center ${!onboardingTicket && wallet?.length && wallet?.length > 2 ? 'pt-[5vh]' : wallet?.length === 1 ? 'pt-[3vh]' : wallet?.length === 2 ? 'pt-[5vh]' : ''}`}
                  transition={{
                    ease: easeIn,
                    duration: 0.325,
                  }}
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                >
                  {onboardingTicket ? (
                    <OnboardingTicket
                      setOnboardingTicket={setOnboardingTicket}
                    />
                  ) : (
                    <TicketStack />
                  )}
                </motion.ul>,
              )}
            </div>
          </NavLayout>
        </>,
      )}

      {/* Search Seats */}
      {useConditionalRender(
        searchingSeats,
        <DefaultLayout>
          <AnimatePresence>
            <motion.div
              className="search relative w-full h-full overflow-hidden flex flex-col items-center justify-center gap-4"
              transition={transition}
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
            >
              {!mapSeats ? (
                <SeatsLoader />
              ) : !mapSeats?.find((seat) => seat.band_uid) ? (
                <SeatsNotFound />
              ) : null}
            </motion.div>
          </AnimatePresence>
        </DefaultLayout>,
      )}
    </div>
  )
}
