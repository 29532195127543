import {
  Drawer,
  DrawerClose,
  DrawerContent,
  DrawerDescription,
  DrawerFooter,
  DrawerHeader,
  DrawerTitle,
} from '@/components/ui/drawer'
import { Button } from '@/components/ui/button'
import { Plus } from 'lucide-react'
import { AppleWallet } from '@/assets/AppleWallet'
interface ShareTicketProps {
  saveOpen: boolean
  setSaveOpen: (value: boolean) => void
}

export const SaveTicket = ({ saveOpen, setSaveOpen }: ShareTicketProps) => {
  return (
    <Drawer open={saveOpen} onClose={() => setSaveOpen(!saveOpen)}>
      <DrawerContent>
        <div className="mx-auto w-full max-w-sm">
          <DrawerHeader className="relative flex justify-between">
            <div className="flex flex-col gap-3">
              <DrawerTitle>{`Tap to save to your Apple Wallet`}</DrawerTitle>
              <DrawerDescription>{``}</DrawerDescription>
            </div>
            <DrawerClose asChild>
              <Button
                variant="primaryDark"
                className="absolute top-0 right-0 py-0 px-4"
              >
                <Plus
                  width={32}
                  height={32}
                  className="z-10 rotate-45 font-light"
                />
              </Button>
            </DrawerClose>
          </DrawerHeader>
          <DrawerFooter>
            <DrawerClose asChild>
              <div
                data-testId="apple-wallet"
                className="w-full flex justify-center"
              >
                <AppleWallet />
              </div>
            </DrawerClose>
          </DrawerFooter>
        </div>
      </DrawerContent>
    </Drawer>
  )
}
