import { Channel } from '@/types/preshow'
import { create } from 'zustand'

// Define the store's state
type ContentChannelState = {
  channelContent: Channel
  setChannelContent: (value: Channel) => void
  fullScreen: boolean
  setFullScreen: (value: boolean) => void
  meetTheCast: boolean
  setMeetTheCast: (value: boolean) => void
  blockSwipeY: boolean
  setBlockSwipeY: (value: boolean) => void
}

// Create the store
export const useContentChannelStore = create<ContentChannelState>((set) => ({
  channelContent: {
    uid: '',
    name: '',
    description: '',
    badge: '',
    channel: [],
    actions: [],
  },
  setChannelContent: (value) => set({ channelContent: value }),
  fullScreen: false,
  setFullScreen: (value) => set({ fullScreen: value }),
  meetTheCast: false,
  setMeetTheCast: (value) => set({ meetTheCast: value }),
  blockSwipeY: false,
  setBlockSwipeY: (value) =>
    set({
      blockSwipeY: value,
    }),
}))
