import { Basket } from '../types'
import { create } from 'zustand'

export enum BasketType {
  UPGRADE = 'upgrade',
  SWAP = 'swap',
  REFUND = 'refund',
}

// Define the store's state type
type BasketState = {
  basketType?: BasketType
  basket: Basket | undefined
  setBasket: (basket: Basket) => void
  removeBasket: () => void
  setBasketType: (basketType: BasketType) => void
}

// Create the store
export const useBasketStore = create<BasketState>((set) => ({
  basketType: undefined,
  basket: undefined,
  setBasket: (basket) => set({ basket }),
  removeBasket: () => set({ basket: undefined }),
  setBasketType: (basketType) => set({ basketType }),
}))
