import { useEffect, useRef, useState } from 'react'
import { useParams } from 'react-router-dom'

import { ChannelItem } from '@/types/preshow'
import { GridVideo } from './GridVideo'
import { isVideo } from '@/utils'
import { motion } from 'framer-motion'
import { useFullScreenContentStore } from '@/context/useFullScreenContentStore'
import { useContentChannelStore } from '@/context/useContentChannelStore'

interface GridProps {
  channelContent: ChannelItem[]
  childVariants: any
  badge: string
  video_url: string
  video_description: string
  name: string
}

export const GridTile = ({
  channelContent,
  badge,
  name,
  childVariants,
}: GridProps) => {
  const { setFullScreen } = useContentChannelStore()
  const params = useParams()
  const { fullScreenChannel, setFullScreenIndex, setMainContent } =
    useFullScreenContentStore()
  const [videoResolution, setVideoResolution] = useState({
    width: 0,
    height: 0,
  })
  const [gridImagesLoaded, setGridImagesLoaded] = useState(false)
  const [gridVideosLoaded, setGridVideosLoaded] = useState(false)

  const imageRef = useRef<HTMLImageElement>(null)
  const videoRef = useRef<HTMLVideoElement>(null)
  const ref = useRef(null)

  useEffect(() => {
    const videoElement = videoRef.current
    const imageElement = imageRef.current

    const handleLoadedMetadata = () => {
      if (videoElement) {
        const width = videoElement.videoWidth
        const height = videoElement.videoHeight
        setVideoResolution({ width, height })
      }
    }

    if (videoElement)
      videoElement.addEventListener('loadedmetadata', handleLoadedMetadata)
    if (imageElement)
      imageElement.addEventListener('load', handleLoadedMetadata)

    return () => {
      if (videoElement)
        videoElement.removeEventListener('loadedmetadata', handleLoadedMetadata)
      if (imageElement)
        imageElement.removeEventListener('load', handleLoadedMetadata)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <motion.div
      variants={childVariants}
      ref={ref}
      initial={{
        opacity: 0,
      }}
      animate={{ opacity: gridVideosLoaded ? 100 : 0 }}
      transition={{ staggerChildren: 0.1 }}
      className="flex flex-wrap h-full w-full overflow-hidden bg-purple"
    >
      {channelContent
        .filter((cont: ChannelItem) => isVideo(cont.content))
        .map((channelItem, i) => {
          return (
            <motion.div
              key={channelContent[i].content}
              className="relative flex flex-col pt-1"
            >
              <motion.div>
                <GridVideo
                  url={channelItem.content}
                  description={channelItem.description as string}
                  videoResolution={videoResolution}
                  setCurrentEvent={() => {
                    setFullScreenIndex(
                      fullScreenChannel.findIndex(
                        (c) => channelItem.y_axis === c?.y_axis,
                      ),
                    )
                    setMainContent({
                      badge,
                      video_url: channelItem.content,
                      video_description: channelItem.description as string,
                      name,
                    })
                    setFullScreen(true)
                  }}
                  setGridVideosLoaded={setGridVideosLoaded}
                />
              </motion.div>
            </motion.div>
          )
        })}
    </motion.div>
  )
}
