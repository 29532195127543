import * as React from 'react'
import { Slot } from '@radix-ui/react-slot'
import { cva, type VariantProps } from 'class-variance-authority'

import { cn } from '@/utils'
import { MoveRight } from 'lucide-react'

const buttonVariants = cva(
  'flex z-[100] gap-[4px] my-4 relative bottom-0 items-center p-[4px] tracking-[0.25px] justify-between bg-white/90 hover:bg-white/100 active:cursor-pointer focus:bg-white/90 focus:cursor-pointer font-bold capitalize w-[90%] min-w-[10vw] text-purple text-sm leading-[22px] whitespace-nowrap font-bold ring-offset-white transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-slate-950 focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 dark:ring-offset-slate-950 dark:focus-visible:ring-slate-300',
  {
    variants: {
      dark: {
        true: 'w-fit m-0 py-2.5 bg-[linear-gradient(85.87deg,_#19162c_5%,_#bf3ed9_90%)] text-yellow [&>span]:bg-none [&>span]:bg-white/50',
      },
    },
  },
)

export interface ButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement>,
    VariantProps<typeof buttonVariants> {
  asChild?: boolean
  disabled?: boolean
  dark?: boolean
}

const ContentChannelButton = React.forwardRef<HTMLButtonElement, ButtonProps>(
  ({ className, dark, asChild = false, ...props }, ref) => {
    const Comp = asChild ? Slot : 'button'

    return (
      <Comp
        className={cn(buttonVariants({ dark, className }))}
        ref={ref}
        {...props}
      >
        <p
          className={`w-full relative py-[2px] ${dark && 'pl-4 pr-10'}`}
        >{`Exclusive ${!dark ? 'Pre-Show' : ''} Content`}</p>
        <span
          className={`absolute ${dark ? 'right-[8px]' : 'right-[4px]'} bg-[linear-gradient(85.87deg,_#19162c_5%,_#bf3ed9_90%)] p-2 rounded-full`}
        >
          <MoveRight color={'white'} size={14} />
        </span>
      </Comp>
    )
  },
)
ContentChannelButton.displayName = 'Button'

export { ContentChannelButton, buttonVariants }
