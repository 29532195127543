import './App.scss'
import { motion } from 'framer-motion'
import {
  Route,
  Routes,
  useLocation,
  useNavigate,
  useSearchParams,
} from 'react-router-dom'
import { GoogleOAuthProvider } from '@react-oauth/google'
import { useEffect, useState } from 'react'

import Hotjar from '@hotjar/browser'
import SentryInitialization from './helpers/sentry'
import UpAppLogo from './assets/logos/upseat_logo.svg'
import { cleanTokenFromLocalStorage } from './utils'
import { getUser } from './service/User/getUser'
import { userExists } from './service/User/userExists'

import { useAppStore } from './context/useAppStore'
import { useUserStore } from '@/context/useUserStore'

import useScreenSize from './hooks/useScreenSize'
import { useVersionChecker } from './hooks/useVersionChecker'
import { toast } from './hooks/useToast'

import NetworkStatus from './components/upseat-ui/app/networkStatus'
import ProtectedRoutes from './components/upseat-ui/app/protectedRoutes'
import PublicRoutes from './components/upseat-ui/app/publicRoutes'
import { Toaster } from './components/ui/toaster'

import { ContentChannel } from './pages/contentChannel'

function App() {
  const navigate = useNavigate()
  const location = useLocation()
  const [searchParams] = useSearchParams()
  const screen = useScreenSize()

  const { user, setUser } = useUserStore()
  const { errors, ui, loading, setAppStore } = useAppStore()
  const [updateAvailable, setUpdateAvailable] = useState(false)

  useVersionChecker(setUpdateAvailable)

  // Fetch user data
  useEffect(() => {
    if (!user) fetchUserData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user])

  // Handle deep link for sign-up
  useEffect(() => {
    handleDeepLink()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // Display update notification
  useEffect(() => {
    if (updateAvailable) {
      toast({
        title: 'New version available!',
        description: 'Please refresh the page to update the app',
      })
    }
  }, [updateAvailable])

  // Display error toasts
  useEffect(() => {
    if (errors.server)
      showErrorToast(
        'Server Error',
        'Something went wrong, please try again later',
      )
  }, [errors.server])

  useEffect(() => {
    if (errors.network)
      showErrorToast('Network Error', 'It looks like you are offline')
  }, [errors.network])

  // Initialize Hotjar
  useEffect(() => {
    if (ui.cookiesConsent) {
      Hotjar.init(parseInt(process.env.REACT_APP_HOTJAR_ID as string), 6, {
        debug: true,
      })
      Hotjar.stateChange(location.pathname)
    }
  }, [ui.cookiesConsent, location])

  // Fetch user data from token
  async function fetchUserData() {
    setAppStore({ loading: { app: true, initial: false } })
    const token = await localStorage.getItem('access_token')

    if (navigator.onLine && !user && token) {
      const userFromToken = await getUser()
        .then((res) => (res ? formatUser(res) : null))
        .finally(() => setAppStore({ loading: { app: false, initial: false } }))

      userFromToken ? setUser(userFromToken) : cleanTokenFromLocalStorage()
    } else {
      setAppStore({ loading: { app: false, initial: false } })
    }
  }

  // Handle deep link email check
  function handleDeepLink() {
    const email = searchParams.get('email')
    if (!email) return

    userExists(email).then(async (res) => {
      if (res.data.exists) {
        navigate('/login')
      } else {
        navigate(`/sign-up?${searchParams.toString()}`)
      }
    })
  }

  function formatUser(res: any) {
    return {
      id: res.uid,
      username: res.email.split('@')[0],
      firstname: res.first_name,
      lastname: res.last_name,
      email: res.email,
      phoneNumber: res.phone_number,
      countryCode: { acronym: 'GB', code: '+44', digits: '44' },
    }
  }

  function showErrorToast(title: string, description: string) {
    toast({ title, description, error: true })
  }

  return (
    <div data-testid="up-app">
      <NetworkStatus />
      <Toaster />

      {!loading.app ? (
        <div className="upseat w-screen h-[100svh] bg-purple overflow-hidden flex">
          <GoogleOAuthProvider
            clientId={process.env.REACT_APP_GOOGLE_OAUTH_CLIENT_ID as string}
          >
            {user ? <SentryInitialization user={user} /> : null}
            <Routes>
              <Route path="/channel/:id" element={<ContentChannel />} />
              <Route
                path="*"
                element={
                  user ? <ProtectedRoutes user={user} /> : <PublicRoutes />
                }
              />
            </Routes>
          </GoogleOAuthProvider>
        </div>
      ) : (
        <LoadingScreen screen={screen} />
      )}
    </div>
  )
}

// Extracted loading screen component
const LoadingScreen = ({ screen }: { screen: { width: number } }) => (
  <div className="bg-purple gap-4 w-[100vw] overflow-hidden h-[100svh] flex flex-col justify-center items-center text-white">
    <motion.img
      src={UpAppLogo}
      alt="upSeat-logo"
      className={`${screen.width > 1024 ? 'w-[4vw]' : 'w-[20vw]'}`}
      initial={{ scale: 0.5 }}
      animate={{ rotate: 0, scale: 1 }}
      transition={{ type: 'spring', stiffness: 260, damping: 20 }}
    />
  </div>
)

export default App
