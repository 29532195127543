import { AnimatePresence, motion } from 'framer-motion'
import { ArrowRightLeft, Download, PoundSterlingIcon, Send } from 'lucide-react'
import { Button } from '@/components/ui/button'
import { transition } from '@/utils/index'
import { useState } from 'react'

interface ManageTicketButtonProps {
  setManageTicket: (value: boolean) => void
  manageTicket: boolean
  setSwapOpen?: (value: boolean) => void
  setShareOpen?: (value: boolean) => void
  setSaveOpen?: (value: boolean) => void
  setRefundOpen?: (value: boolean) => void
}

const containerVariants = {
  hidden: {
    opacity: 0,
  },
  visible: {
    opacity: 1,
    y: 0,
    transition: {
      ...transition,
      delay: 0,
      staggerChildren: 0.2,
      when: 'beforeChildren',
    },
  },
  exit: {
    opacity: 0,
    y: 10,
    transition: {
      duration: 0.1,
      staggerChildren: 0.1,
      when: 'afterChildren',
    },
  },
}

const childVariants = {
  hidden: { opacity: 0, y: -20, x: 0 },

  visible: (index: number) => {
    const yOffsetMap = ['-1.5vh', '7vh', '7vh', '-1.5vh']
    const xOffsetMap = [-10, -12, 12, 10]

    return {
      opacity: 1,
      y: yOffsetMap[index] ?? '4vh',
      x: xOffsetMap[index] ?? 0,
    }
  },

  exit: (index: number) => ({
    opacity: 0,
    y: 0,
    x: { 0: 30, 2: -30 }[index] ?? 0,
  }),
}

export const ManageTicketButton = ({
  setManageTicket,
  manageTicket,
  setSwapOpen,
  setShareOpen,
  setSaveOpen,
  setRefundOpen,
}: ManageTicketButtonProps) => {
  const [isAnimating, setIsAnimating] = useState(false)
  const items = [
    { text: 'swap', icon: <ArrowRightLeft size={16} /> },
    { text: 'send', icon: <Send size={16} /> },
    { text: 'save', icon: <Download size={16} /> },
    { text: 'refund', icon: <PoundSterlingIcon size={16} /> },
  ]

  const handleClick = (e: { text: string; icon: JSX.Element }) => {
    switch (e.text) {
      case 'swap':
        setSwapOpen && setSwapOpen(true)
        break
      case 'send':
        setShareOpen && setShareOpen(true)
        break
      case 'save':
        setSaveOpen && setSaveOpen(true)
        break
      case 'refund':
        setRefundOpen && setRefundOpen(true)
        break
      default:
        break
    }
  }

  return (
    <>
      <AnimatePresence>
        {manageTicket && (
          <motion.div
            className="absolute w-full px-[5vw] flex justify-center items-center text-yellow capitalize"
            initial="hidden"
            animate="visible"
            exit="exit"
            variants={containerVariants}
            onAnimationStart={() => setIsAnimating(true)}
            onAnimationComplete={() => setIsAnimating(false)}
          >
            {items.map((item, index) => (
              <motion.div
                key={item.text}
                variants={childVariants}
                custom={index}
              >
                <div
                  onClick={(e: { stopPropagation: () => void }) => {
                    e.stopPropagation()
                    if (!isAnimating) {
                      handleClick(item)
                    }
                  }}
                >
                  <span className="flex flex-col gap-1 cursor-pointer text-[12px] shadow-[4px_10px_15px_-3px_rgb(0_0_0_/_0.3),4px_4px_6px_0px_rgb(0_0_0_/_0.3)] justify-center items-center bg-purple py-4 w-[64px] h-[64px] rounded-full [&>svg]:relative [&>svg]:bottom-[1.5px]">
                    <p>{item.icon}</p>
                    {item.text}
                  </span>
                </div>
              </motion.div>
            ))}
          </motion.div>
        )}
      </AnimatePresence>

      <Button
        variant="textOnly"
        size="small"
        className={`${manageTicket && 'blur-[2px] opacity-70'} ${isAnimating && 'transition-opacity ease opacity-70 pointer-events-none'} absolute border font-bold text-sm text-purple border-purple border-2 py-2 z-[100]`}
        onClick={(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
          event.stopPropagation()
          if (!isAnimating) {
            setManageTicket(!manageTicket)
          }
        }}
      >
        {`Manage`}
      </Button>
    </>
  )
}
