'use client'

import React, { useEffect, useRef, useState } from 'react'

import { ExpandableText } from './ExpandableText'
import { PlayIcon } from '@/assets/PlayIcon'
import { VolumeOff } from '@/assets/volumeOff'
import { VolumeOn } from '@/assets/volumeOn'

interface GridVideoProps {
  url: string
  description: string
  videoResolution: {
    width: number
    height: number
  }
  setCurrentEvent: (contentUrl: string) => void
  setGridVideosLoaded: (value: boolean) => void
}

export const GridVideo: React.FC<GridVideoProps> = ({
  url,
  description,
  setCurrentEvent,
  setGridVideosLoaded,
}) => {
  const videoRef = useRef<HTMLVideoElement>(null)
  const containerRef = useRef<HTMLDivElement>(null)
  const [isMuted, setIsMuted] = useState(true)
  const [isPlaying, setIsPlaying] = useState(false)
  const [isExpanded, setIsExpanded] = useState(false)
  const [poster, setPoster] = useState<string>('')

  // Use canvas to extract the first frame of the video
  useEffect(() => {
    const video = videoRef.current
    if (!video) return

    const canvas = document.createElement('canvas')
    const ctx = canvas.getContext('2d')
    if (ctx) {
      // Ensure the canvas matches the video resolution
      canvas.width = video.videoWidth
      canvas.height = video.videoHeight

      video.addEventListener('loadeddata', () => {
        // Draw the first frame
        ctx.drawImage(video, 0, 0, canvas.width, canvas.height)
        setPoster(canvas.toDataURL()) // Set the poster as the first frame image
      })
    }
  }, [url])

  // Ensure video metadata is preloaded on page load
  useEffect(() => {
    const video = videoRef.current
    if (video) {
      video.load() // Preload the video metadata
    }
  }, [])

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        const video = videoRef.current
        if (!video) return

        if (entry.isIntersecting) {
          video
            .play()
            .catch((error) => console.error('Video play failed:', error))
          setIsPlaying(true)
        } else {
          video.pause()
          setIsPlaying(false)
          setIsMuted(true) // Auto mute when out of view
        }
      },
      { threshold: 1 }, // Only play when 100% in view
    )

    const container = containerRef.current
    if (container) observer.observe(container)

    return () => {
      if (container) observer.unobserve(container)
      observer.disconnect()
    }
  }, [])

  return (
    <div
      ref={containerRef}
      className="relative w-full h-full flex justify-center items-center"
    >
      <video
        preload="metadata"
        ref={videoRef}
        loop
        controls={false}
        muted={isMuted}
        playsInline
        className="w-[100vw] h-full max-h-[50vh] object-cover transition-opacity duration-500"
        onCanPlay={() => setGridVideosLoaded(true)}
        onCanPlayThrough={() => setGridVideosLoaded(true)}
        onClick={() => setCurrentEvent(url)}
      >
        <source src={url} />
      </video>

      {!isPlaying && (
        <div className="absolute">
          <div
            className="[&>svg]:relative [&>svg]:left-[1px] cursor-pointer z-50 bg-white/50 rounded-full w-16 h-16 flex items-center justify-center"
            onClick={() => videoRef.current?.play()}
          >
            <PlayIcon />
          </div>
        </div>
      )}

      <div
        className={`absolute bottom-[-1px] flex w-full items-center justify-between p-4 ${
          description &&
          'bg-[linear-gradient(to_top,rgba(0,0,0,.6)_80%,rgba(0,0,0,0)_100%)]'
        }`}
      >
        {description && (
          <div className="w-full pr-4">
            <ExpandableText
              isExpanded={isExpanded}
              setIsExpanded={setIsExpanded}
              text={description}
              limit={60}
            />
          </div>
        )}
        <div className={`${!description && 'absolute right-0 bottom-0 p-4'}`}>
          <div
            className="cursor-pointer z-50 bg-white/50 rounded-full w-8 h-8 flex items-center justify-center"
            onClick={() => setIsMuted(!isMuted)}
          >
            {isMuted ? <VolumeOff /> : <VolumeOn />}
          </div>
        </div>
      </div>
    </div>
  )
}
