import {
  Drawer,
  DrawerClose,
  DrawerContent,
  DrawerDescription,
  DrawerFooter,
  DrawerHeader,
  DrawerTitle,
} from '@/components/ui/drawer'
import { Button } from '@/components/ui/button'
import { Copy, MoveRight, Plus } from 'lucide-react'
import useCallRouteWithDirection from '@/hooks/useCallRouteWithDirection'
import { Input } from '@/components/ui/input'
import { toast } from '@/hooks/useToast'

interface ShareTicketProps {
  shareOpen: boolean
  setShareOpen: (value: boolean) => void
}

export const ShareTicket = ({ shareOpen, setShareOpen }: ShareTicketProps) => {
  const callRouteWithDirection = useCallRouteWithDirection()

  return (
    <Drawer open={shareOpen} onClose={() => setShareOpen(!shareOpen)}>
      <DrawerContent>
        <div className="mx-auto w-full max-w-sm">
          <DrawerHeader className="relative flex justify-between">
            <div className="flex flex-col gap-3">
              <DrawerTitle>{`Going with friends or family?`}</DrawerTitle>
              <DrawerDescription>{`Add them to this ticket.`}</DrawerDescription>
            </div>
            <DrawerClose asChild>
              <Button
                variant="primaryDark"
                className="absolute top-0 right-0 py-0 px-4"
              >
                <Plus
                  width={32}
                  height={32}
                  className="z-10 rotate-45 font-light"
                />
              </Button>
            </DrawerClose>
          </DrawerHeader>
          <DrawerFooter>
            <div className="relative flex justify-center items-center">
              <Input
                disabled
                variant="dark"
                className="text-purple6 pr-10"
                defaultValue={`www.upapp.uk/fdwjbfg9r384gt47feb`}
              />
              <Copy
                size={16}
                className="absolute right-4 cursor-pointer"
                onClick={() => {
                  navigator.clipboard.writeText(
                    'www.upapp.uk/fdwjbfg9r384gt47feb',
                  )

                  toast({
                    title: 'Copied to clipboard!',
                  })
                }}
              />
            </div>
            <DrawerClose asChild>
              <Button variant="primaryYellow">
                {`Share link`}
                <MoveRight color="#19162C" />
              </Button>
            </DrawerClose>
          </DrawerFooter>
        </div>
      </DrawerContent>
    </Drawer>
  )
}
