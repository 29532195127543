import * as React from 'react'
import useEmblaCarousel, {
  type UseEmblaCarouselType,
} from 'embla-carousel-react'
import { ChevronLeft } from 'lucide-react'

import { cn } from '@/utils'
import { Button } from '@/components/ui/button'
import { useEffect } from 'react'
import { useAppStore } from '@/context/useAppStore'
import { ArrowLong } from '@/assets/ArrowLong'

type CarouselApi = UseEmblaCarouselType[1]
type UseCarouselParameters = Parameters<typeof useEmblaCarousel>
type CarouselOptions = UseCarouselParameters[0]
type CarouselPlugin = UseCarouselParameters[1]

type CarouselProps = {
  opts?: CarouselOptions
  plugins?: CarouselPlugin
  orientation?: 'horizontal' | 'vertical'
  setApi?: (api: CarouselApi) => void
  onIndexChange: (index: number) => void
}

type CarouselContextProps = {
  carouselRef: ReturnType<typeof useEmblaCarousel>[0]
  api: ReturnType<typeof useEmblaCarousel>[1]
  scrollPrev: () => void
  scrollNext: () => void
  canScrollPrev: boolean
  canScrollNext: boolean
  currentIndex: number
  totalSlides: number
} & CarouselProps

const CarouselContext = React.createContext<CarouselContextProps | null>(null)

function useCarousel() {
  const context = React.useContext(CarouselContext)

  if (!context) {
    throw new Error('useCarousel must be used within a <Carousel />')
  }

  return context
}

const Carousel = React.forwardRef<
  HTMLDivElement,
  React.HTMLAttributes<HTMLDivElement> & CarouselProps
>(
  (
    {
      orientation = 'horizontal',
      opts,
      setApi,
      onIndexChange,
      plugins,
      className,
      children,
      ...props
    },
    ref,
  ) => {
    const [carouselRef, api] = useEmblaCarousel(
      {
        ...opts,
        axis: orientation === 'horizontal' ? 'x' : 'y',
      },
      plugins,
    )
    const [canScrollPrev, setCanScrollPrev] = React.useState(false)
    const [canScrollNext, setCanScrollNext] = React.useState(false)
    const [currentIndex, setCurrentIndex] = React.useState(0)
    const [totalSlides, setTotalSlides] = React.useState(0)

    useEffect(() => {
      onIndexChange(currentIndex)
    }, [currentIndex, onIndexChange])

    const onSelect = React.useCallback((api: CarouselApi) => {
      if (!api) {
        return
      }

      setCanScrollPrev(api.canScrollPrev())
      setCanScrollNext(api.canScrollNext())
      setCurrentIndex(api.selectedScrollSnap())
      setTotalSlides(api.scrollSnapList().length)
    }, [])

    const scrollPrev = React.useCallback(() => {
      api?.scrollPrev()
    }, [api])

    const scrollNext = React.useCallback(() => {
      api?.scrollNext()
    }, [api])

    const handleKeyDown = React.useCallback(
      (event: React.KeyboardEvent<HTMLDivElement>) => {
        if (event.key === 'ArrowLeft') {
          event.preventDefault()
          scrollPrev()
        } else if (event.key === 'ArrowRight') {
          event.preventDefault()
          scrollNext()
        }
      },
      [scrollPrev, scrollNext],
    )

    React.useEffect(() => {
      if (!api || !setApi) {
        return
      }

      setApi(api)
    }, [api, setApi])

    React.useEffect(() => {
      if (!api) {
        return
      }

      onSelect(api)
      api.on('reInit', onSelect)
      api.on('select', onSelect)

      return () => {
        api?.off('select', onSelect)
      }
    }, [api, onSelect])

    return (
      <CarouselContext.Provider
        value={{
          carouselRef,
          api: api,
          opts,
          orientation:
            orientation || (opts?.axis === 'y' ? 'vertical' : 'horizontal'),
          scrollPrev,
          scrollNext,
          canScrollPrev,
          canScrollNext,
          currentIndex,
          totalSlides,
          onIndexChange,
        }}
      >
        <div
          ref={ref}
          onKeyDownCapture={handleKeyDown}
          className={cn('relative', className)}
          role="region"
          aria-roledescription="carousel"
          {...props}
        >
          {children}
        </div>
      </CarouselContext.Provider>
    )
  },
)
Carousel.displayName = 'Carousel'

interface CarouselContentProps {
  className?: string
  setCarouselCurrentIndex?: (index: number) => void
  children: React.ReactNode
}

const CarouselContent = React.forwardRef<HTMLDivElement, CarouselContentProps>(
  ({ className, setCarouselCurrentIndex, children, ...props }, ref) => {
    const { carouselRef, orientation, currentIndex, totalSlides } =
      useCarousel()

    useEffect(() => {
      setCarouselCurrentIndex && setCarouselCurrentIndex(currentIndex)
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentIndex])

    return (
      <div ref={carouselRef} className="overflow-hidden relative h-auto w-full">
        <div
          ref={ref}
          className={cn(
            'flex',
            // orientation === 'horizontal' ? '-ml-4' : '-mt-4 flex-col',
            className,
          )}
          {...props}
        >
          {children}
        </div>
      </div>
    )
  },
)
CarouselContent.displayName = 'CarouselContent'

const CarouselItem = React.forwardRef<
  HTMLDivElement,
  React.HTMLAttributes<HTMLDivElement> & {
    button?: React.ReactElement
  }
>(({ className, ...props }, ref) => {
  const { orientation } = useCarousel()

  return (
    <div
      ref={ref}
      role="group"
      aria-roledescription="slide"
      className={cn('min-w-0 shrink-0 grow-0 basis-3/5', className)}
    >
      {props.children}
    </div>
  )
})

CarouselItem.displayName = 'CarouselItem'

const CarouselPrevious = React.forwardRef<
  HTMLButtonElement,
  React.ComponentProps<typeof Button>
>(({ className, variant = 'primaryDark', size = 'small', ...props }, ref) => {
  const { orientation, scrollPrev, canScrollPrev } = useCarousel()

  return (
    <Button
      ref={ref}
      variant={variant}
      size={size}
      className={cn(
        'absolute h-8 w-8 rounded-full mt-6',
        orientation === 'horizontal'
          ? '-left-10 top-1/2 -translate-y-1/2'
          : '-top-12 left-1/2 -translate-x-1/2 rotate-90',
        className,
      )}
      disabled={!canScrollPrev}
      onClick={scrollPrev}
      {...props}
    >
      <ChevronLeft className="h-6 w-6 shrink-0 text-white transition-transform duration-200" />
      <span className="sr-only">Previous slide</span>
    </Button>
  )
})
CarouselPrevious.displayName = 'CarouselPrevious'

const CarouselNext = React.forwardRef<
  HTMLButtonElement,
  React.ComponentProps<typeof Button> & {
    setOnboardingTicket: () => void
  }
>(
  (
    {
      className,
      variant = 'primaryDark',
      size = 'small',
      setOnboardingTicket,
      ...props
    },
    ref,
  ) => {
    const { scrollNext, currentIndex } = useCarousel()
    const { ui, setAppStore } = useAppStore()

    const handleClick = (acceptCookies: boolean) => {
      if (acceptCookies) {
        setAppStore({
          ui: {
            ...ui,
            cookiesConsent: true,
          },
        })
        console.log('User accepted cookies')
      } else {
        console.log('User declined cookies')
      }

      setOnboardingTicket?.()
    }

    return (
      <div className="w-full flex flex-col items-center">
        {currentIndex === 1 && (
          <Button variant="textOnly" onClick={() => handleClick(false)}>
            <span className="text-white">No thanks</span>
          </Button>
        )}
        <Button
          ref={ref}
          id="onboarding-carousel-next"
          variant={variant}
          role="button"
          size={size}
          className="w-full text-purple px-8 py-4"
          onClick={() =>
            currentIndex !== 1 ? scrollNext() : handleClick(true)
          }
          {...props}
        >
          {currentIndex !== 1 ? 'Continue' : 'Accept'}
          <ArrowLong />
        </Button>
      </div>
    )
  },
)

CarouselNext.displayName = 'CarouselNext'

export const CarouselDots = () => {
  const { currentIndex, totalSlides, api } = useCarousel()

  const scrollTo = (index: number) => {
    api?.scrollTo(index)
  }

  return totalSlides <= 1 ? null : (
    <div
      className={`carousel-dots rounded-full flex gap-1 justify-center items-center`}
    >
      {Array.from({ length: totalSlides }).map((_, index) => (
        <button
          key={index}
          className={cn(
            'w-2 h-2 rounded-full flex justify-center transition-all ease-in-out duration-300',
            currentIndex === index ? 'bg-white w-6' : 'bg-purple4',
          )}
          onClick={() => scrollTo(index)}
        />
      ))}
    </div>
  )
}

export {
  type CarouselApi,
  Carousel,
  CarouselContent,
  CarouselItem,
  CarouselPrevious,
  CarouselNext,
}
