import { forwardRef, memo, useEffect } from 'react'

interface SeekBarProps {
  videoRef?: React.RefObject<HTMLVideoElement>
  currentIndex: number
  duration: number
  currentTime: number
  setCurrentIndex?: (currentIndex: number) => void
  setCurrentTime: (currentTime: number) => void
  handleSkipContent?: (next?: boolean) => void
  isActive: boolean
}

const SeekBarComponent = forwardRef<HTMLVideoElement, SeekBarProps>(
  (
    {
      videoRef,
      duration,
      currentTime,
      setCurrentTime,
      handleSkipContent,
      isActive,
    },
    ref,
  ) => {
    const handleSeek = (e: React.ChangeEvent<HTMLInputElement>) => {
      const newTime = parseFloat(e.target.value)
      if (ref && 'current' in ref && ref.current) {
        ref.current.currentTime = newTime
      }
      setCurrentTime(newTime)
    }

    useEffect(() => {
      const updateProgress = () => {
        if (videoRef?.current) {
          setCurrentTime(videoRef.current.currentTime)
        }
        requestAnimationFrame(updateProgress)
      }

      const animationFrameId = requestAnimationFrame(updateProgress)

      return () => cancelAnimationFrame(animationFrameId)
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
      if (isActive && currentTime === duration) {
        handleSkipContent?.(true)
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentTime])

    return (
      <div className="relative w-full">
        <span
          className={`bg-white/30 rounded-[12px] absolute h-1 w-full `}
        ></span>

        <input
          type="range"
          className="absolute w-full h-1 rounded-[12px] appearance-none bg-white/30 cursor-pointer"
          min="0"
          max={duration.toString()}
          step={0.01}
          value={currentTime}
          onChange={handleSeek}
          readOnly={!isActive}
          style={{
            background: `linear-gradient(to right, #F4FF68 ${(
              (currentTime / duration) *
              100
            ).toFixed(2)}%, transparent 0%)`,
          }}
        />
      </div>
    )
  },
)

// Memoize the component to avoid unnecessary re-renders
export const SeekBar = memo(SeekBarComponent)
